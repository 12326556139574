<template>
 <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}</p>
                        <p v-b-modal.modal-3 variant="link" class="mb-0"><u>1688구매내역 다운로드 방법</u></p>
                                    <b-modal id="modal-3" size="xl" title="1688구매내역 다운로드 방법" ok-title="Save Changes" cancel-title="Close">
                                        <p><a href="https://www.1688.com" target="_blank"><u>1688</u></a>에 접속해서 아래의 순서로 엑셀을 다운받은 후 업로드해주세요</p>
                                        <img  style="max-width:100%;" :src="require('../../../assets/images/small/1688주문서다운로드.png')">
                                    </b-modal>
                    </div>

                    <button type="button" v-b-modal.modal class="btn btn-primary add-list"><i class="las la-plus mr-3"></i>{{linktext}}</button>
                    <b-modal ref="bvModal" id="modal" size="xl" title="1688 구매내역 업로드" ok-title="매칭저장" cancel-title="취소" @ok="handleOk" @cancel="cancel">
                        <div class="col-md-12">
                            <p>1688 구매내역을 업로드 해주세요. &nbsp;&nbsp;적용환율 : <span><input style="width:80px" type="number" placeholder="적용환율" v-model="환율">원/위안</span></p>
                            <p>*구매단가는 한국가격 기준 1개당 구매가격입니다. (*구매단가 = (구매가격+배송가격+할인가격x환율)/주문수량)</p>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="custom-file">
                                <input ref="pdbupload" type="file" @click="showspinner = true" @change="uploadfile" class="custom-file-input" id="inputGroupFile02">
                                <label class="custom-file-label" for="inputGroupFile02">{{uploadstatus}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12" v-if="showspinner">
                            <div class="text-center">
                                <img style="width:5%;" :src="require('../../../assets/images/small/loading.gif')">
                            </div>
                        </div>

                        <div class="col-lg-12 mt-5" v-if="showmatchlist">
                            <div class="table-responsive rounded mb-3">
                                <tableList>
                                    <template v-slot:header >
                                        <tr class="ligth ligth-data">
                                            <!-- <th>
                                                <div class="checkbox d-inline-block">
                                                    <input type="checkbox" class="checkbox-input" v-model="checkbox1">
                                                    <label for="checkbox1" class="mb-0"></label>
                                                </div>
                                            </th> -->
                                            <th>상품매칭상태</th>
                                            <th>상품매칭</th>
                                            <th>요청매칭상태</th>
                                            <th>요청매칭</th>
                                            <th>상품정보</th>
                                            <th>주문수량</th>
                                            <th>판매처</th>
                                            <th>상품링크</th>
                                            <th>옵션1</th>
                                            <th>옵션2</th>
                                            <th>주문번호</th>
                                            <th style="">삭제</th>
                                        </tr>
                                    </template>
                                    <template v-slot:body >
                                        <tr v-for="(match,index) in matchlists" :key="index" >
                                            <!-- <td>
                                                <div class="checkbox d-inline-block">
                                                    <input type="checkbox" class="checkbox-input" id="checkbox2">
                                                    <label for="checkbox2" class="mb-0"></label>
                                                </div>
                                            </td> -->
                                            <td><div :class="matchclass(match.매칭상태)">{{match.매칭상태}}</div></td>
                                            <td>
                                                <b-dropdown class="my-class" size="xl"  variant="link" toggle-class="text-decoration-none" no-caret v-if="match.상품매칭[0] == '직접선택'">
                                                    <template #button-content>
                                                    &#x1f50d;<span class="sr-only">Search</span>
                                                    </template>
                                                    <b-dropdown-form href="#" v-for="a,i in match.상품매칭" :key="i" >
                                                        <p>상품DB에 없는 구매링크입니다. 상품을 검색해서 직접매칭해주세요</p>
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="SKU ID 검색" v-model="sku검색">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="상품명검색" v-model="상품명검색">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="옵션명 (중국어) 검색" v-model="옵션명검색">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-2">
                                                                <button @click="searchbutton" type="button" class="btn btn-primary h-75">전체검색</button>
                                                            </div>
                                                        </div>
                                                    </b-dropdown-form>
                                                    <b-dropdown-divider v-if="showmatch"></b-dropdown-divider>
                                                    <div class="col-md-12" v-if="showmatchspinner">
                                                        <div class="text-center">
                                                            <img style="width:5%;" :src="require('../../../assets/images/small/loading.gif')">
                                                        </div>
                                                    </div>
                                                    <div v-if="showmatch">
                                                        <b-dropdown-item href="#" v-for="a,i in matches" :key="i" @click="productmatching(a,match)">
                                                            <div class="d-flex align-items-center">
                                                                <img :src="a.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                <div>
                                                                    {{a.상품명}}
                                                                    <p class="mb-0"><small>{{a["SKU ID"]}}&nbsp;&nbsp;{{a.옵션1_중국어}}&nbsp;&nbsp;{{a.옵션2_중국어}}</small></p>
                                                                </div>
                                                            </div>
                                                        </b-dropdown-item>
                                                    </div>
                                                </b-dropdown>
                                                <span v-if="match.상품매칭[0] != '직접선택'">{{match.상품매칭.length}}건</span>
                                                <b-dropdown class="my-class" size="xl"  variant="link" toggle-class="text-decoration-none" no-caret v-if="match.상품매칭[0] != '직접선택'" >
                                                    <template #button-content>
                                                    &#x1f50d;<span class="sr-only">Search</span>
                                                    </template>
                                                    <b-dropdown-form href="#">
                                                        <p>상품을 매칭해주세요</p>
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="SKU ID 검색" v-model="sku검색" @change="searchbuttonin(match,sku검색)">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="상품명검색" v-model="상품명검색" @change="searchbuttonin(match,상품명검색)">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="옵션명 (중국어) 검색" v-model="옵션명검색" @change="searchbuttonin(match,옵션명검색)">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-2">
                                                                <button @click="searchbutton" type="button" class="btn btn-primary h-75">전체검색</button>
                                                            </div>
                                                        </div>
                                                    </b-dropdown-form>
                                                    <b-dropdown-divider v-if="showmatch"></b-dropdown-divider>
                                                    <div v-if="showmatch">
                                                        <b-dropdown-item href="#" v-for="a,i in matches" :key="i" @click="productmatching(a,match)">
                                                            <div class="d-flex align-items-center">
                                                                <img :src="a.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                <div>
                                                                    {{a.상품명}}
                                                                    <p class="mb-0"><small>{{a["SKU ID"]}}&nbsp;&nbsp;{{a.옵션1_중국어}}&nbsp;&nbsp;{{a.옵션2_중국어}}</small></p>
                                                                </div>
                                                            </div>
                                                        </b-dropdown-item>
                                                    </div>
                                                    <div v-if="!showmatch">
                                                        <b-dropdown-item href="#" v-for="a,i in match.상품매칭" :key="i" @click="productmatching(a,match)">
                                                            <div class="d-flex align-items-center">
                                                                <img :src="a.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                <div>
                                                                    {{a.상품명}}
                                                                    <p class="mb-0"><small>{{a["SKU ID"]}}&nbsp;&nbsp;{{a.옵션1_중국어}}&nbsp;&nbsp;{{a.옵션2_중국어}}</small></p>
                                                                </div>
                                                            </div>
                                                        </b-dropdown-item>
                                                    </div>
                                                </b-dropdown>
                                            </td>
                                            <td><div :class="matchclass(match.요청매칭상태)">{{match.요청매칭상태}}</div></td>
                                            <td>
                                                <b-dropdown class="my-class" size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                                                    <template #button-content>
                                                    &#x1f50d;<span class="sr-only">Search</span>
                                                    </template>
                                                        <b-dropdown-item href="#" v-for="a,i in reqlists" :key="i" @click="reqmatching(a,match)">
                                                            <div class="d-flex align-items-center">
                                                                <img :src="a.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                <div>
                                                                    {{a.상품명}}
                                                                    <p class="mb-0"><small>{{a["SKU ID"]}}</small></p>
                                                                    <p class="mb-0"><small>구매요청수량 : {{a.구매요청수량}}</small></p>
                                                                </div>
                                                            </div>
                                                        </b-dropdown-item>
                                                </b-dropdown>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center" v-if="match.등록이미지">
                                                    <div>
                                                        <img :src="match.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                        <p class="mb-0 text-center"><small>{{match.SKUID}}</small></p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{match.주문수량}}</td>
                                            <td>{{match.판매처}}</td>
                                            <td class="text-center"><a :href="match.상품링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a></td>
                                            <td>{{match.중국어옵션1}}</td>
                                            <td>{{match.중국어옵션2}}</td>
                                            <td>{{match.주문번호}}</td>
                                            <td>
                                                <div class="d-flex align-items-center list-action">
                                                    <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete"
                                                        href="javascript:void(0)"><i class="ri-delete-bin-line mr-0"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tableList>
                            </div>
                        </div>
                    </b-modal>

                </div>
            </div>

            <div class="col-lg-12">
            <card>
                <div class="p-3">
                    <form  @submit.prevent="submit" >
                        <div class="row">

                            <div class="col-md-12">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio7" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="today" @change="changedate()">
                                    <label class="custom-control-label" for="customRadio7">오늘</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio8" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="week" @change="changedate()">
                                    <label class="custom-control-label" for="customRadio8">최근7일</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio9" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="month" @change="changedate()">
                                    <label class="custom-control-label" for="customRadio9">최근1개월</label>
                                </div>
                            </div>
                            <div class="col-md-12 mt-2">
                                <b-input-group>
                                    <select class="custom-select col-md-2" style="height:40px" v-model="selectoption">
                                        <option>구매일자</option>
                                        <!-- <option>입고예정일</option> -->
                                    </select>
                                    <b-form-datepicker id="example-datepicker1" size="sm" v-model="startdate" class="mb-2"></b-form-datepicker>
                                    <p>~</p>
                                    <b-form-datepicker id="example-datepicker2" size="sm" v-model="enddate" class="mb-2"></b-form-datepicker>

                                </b-input-group>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="구매주문번호" v-model="주문번호">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="구매송장번호" v-model="운송장번호">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="구매처" v-model="판매처">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="SKU ID" v-model="SKUID">
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary mr-2 mt-1" @click="search">검색</button>
                        <div class="col-md-12" v-if="showsearchspinner">
                            <div class="text-center">
                                <img style="width:5%;" :src="require('../../../assets/images/small/loading.gif')">
                            </div>
                        </div>
                    </form>
                </div>
            </card>
            </div>

            <div class="col-lg-12" v-if="showlist">
                <div class="p-3">
                    <div class="row">
                        <b-button variant="outline-primary" class="mb-1 mr-2" @click="showtotal">
                        전체 <b-badge variant="primary" class="ml-2">{{total}}</b-badge>
                        </b-button>
                        <b-button variant="outline-light" class="mb-1 mr-2" @click="showa">
                        발송전<b-badge variant="light" class="ml-2">{{발송전}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showb">
                        발송지연<b-badge variant="warning" class="ml-2">{{발송지연}}</b-badge>
                        </b-button>
                        <b-button variant="outline-primary" class="mb-1 mr-2" @click="showc">
                        발송완료<b-badge variant="primary" class="ml-2">{{발송완료}}</b-badge>
                        </b-button>
                        <b-button variant="outline-danger" class="mb-1 mr-2" @click="showd">
                        배송지연예정<b-badge variant="danger" class="ml-2">{{배송지연예정}}</b-badge>
                        </b-button>
                        <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showe">
                        배송지연<b-badge variant="secondary" class="ml-2">{{배송지연}}</b-badge>
                        </b-button>
                        <b-button variant="outline-success" class="mb-1 mr-2" @click="showf">
                        구매확정<b-badge variant="success" class="ml-2">{{구매확정}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showg">
                        입고지연<b-badge variant="warning" class="ml-2">{{입고지연}}</b-badge>
                        </b-button>
                    </div>
                    <div class="row">
                        <b-button variant="outline-primary" class="mb-1 mr-2" @click="showk">
                        배송완료<b-badge variant="primary" class="ml-2">{{배송완료}}</b-badge>
                        </b-button>
                        <b-button variant="outline-success" class="mb-1 mr-2" @click="showh">
                        입고완료<b-badge variant="success" class="ml-2">{{입고완료}}</b-badge>
                        </b-button>
                    </div>
                    <div class="row">
                        <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showi">
                        입고수량오류<b-badge variant="secondary" class="ml-2">{{입고수량오류}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showj">
                        입고상품오류<b-badge variant="warning" class="ml-2">{{입고상품오류}}</b-badge>
                        </b-button>
                    </div>
                    <div class="row">
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showl">
                        오류입고처리<b-badge variant="warning" class="ml-2">{{오류입고처리}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showm">
                        오류반품처리<b-badge variant="warning" class="ml-2">{{오류반품처리}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="shown">
                        오류폐기처리<b-badge variant="warning" class="ml-2">{{오류폐기처리}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showo">
                        오류입고대기<b-badge variant="warning" class="ml-2">{{오류입고대기}}</b-badge>
                        </b-button>
                        <b-button variant="outline-success" class="mb-1 mr-2" @click="showp">
                        반품폐기완료<b-badge variant="success" class="ml-2">{{반품폐기완료}}</b-badge>
                        </b-button>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 mt-3" v-if="showlist">
                <div class="table-responsive rounded mb-3">
                     <tableList>
                        <template v-slot:header >
                            <tr class="ligth ligth-data">
                                <th>
                                    <!-- <div class="checkbox d-inline-block">
                                        <input type="checkbox" class="checkbox-input" id="checkbox1" v-model="allcheck" @change="checkall">
                                        <label for="checkbox1" class="mb-0"></label>
                                    </div> -->
                                </th>
                                <th>배송상태</th>
                                <th>배송기간</th>
                                <th>주문날짜</th>
                                <th>입고날짜</th>
                                <th style="min-width:300px;">상품정보</th>
                                <th>주문수량</th>
                                <th>판매처</th>
                                <th>주문번호</th>
                                <th>상품링크</th>
                                <th>운송장번호</th>
                                <th>구매단가</th>
                                <th>Action</th>
                            </tr>
                        </template>
                         <template v-slot:body >
                            <tr v-for="(purchase,index) in purchaselists" :key="index" >
                                <td>
                                    <div class="checkbox d-inline-block">
                                        <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="purchase.checked">
                                        <label for="checkbox2" class="mb-0"></label>
                                    </div>
                                </td>
                                <td><a :href="logislink(purchase)" target="_blank"><div :class="logisclass(purchase)">{{purchase.배송상태}}</div></a></td>
                                <td>{{purchase.배송기간}}</td>
                                <td>{{getdate(purchase.주문날짜)}}</td>
                                <td>{{purchase.입고날짜 === undefined ? '' : getdate(purchase.입고날짜)}}</td>
                                <td v-b-modal="modalID(index)" @click="modaldata(purchase,index)" >
                                    <div class="d-flex align-items-center">
                                        <img :src="purchase.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                        <div>
                                            {{purchase.상품명}}
                                            <p class="mb-0"><small>{{purchase.SKUID}}</small></p>
                                            <p class="mb-0"><small>{{purchase.바코드}}</small></p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="!showedittrackingno">
                                        {{purchase.주문수량}}
                                    </div>
                                    <div v-if="showedittrackingno">
                                        <input type="text" class="form-control" placeholder="상품명검색" v-model="purchase.주문수량" @change="editpurchase(purchase)">
                                    </div>
                                </td>
                                <td>{{purchase.판매처}}</td>
                                <td>{{purchase.주문번호}}</td>
                                <td class="text-center"><a :href="purchase.상품링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a></td>
                                <td>
                                    <div v-if="!showedittrackingno">
                                        {{purchase.운송장번호}}
                                    </div>
                                    <div v-if="showedittrackingno">
                                        <input type="text" class="form-control" placeholder="상품명검색" v-model="purchase.운송장번호" @change="editpurchase(purchase)">
                                    </div>
                                </td>
                                <td>{{parseInt(purchase.총구매단가)}}</td>
                                <td>
                                     <div class="d-flex align-items-center list-action">
                                        <a v-b-modal="modalID(index)" @click="modaldata(purchase,index)" class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="View"
                                            href="javascript:void(0)"><i class="ri-eye-line mr-0"></i></a>

                                                            <b-modal :id="'modal'+index" scrollable title="제품상세" ok-title="OK" cancel-title="Close" class="detail-modal" @ok="refreshpage">
																<div class="row">
																	<div class="col-md-6">
																		<img class="w-100 d-block" :src="purchase.등록이미지" style="border:1px solid silver; border-radius: 30px">
																	</div>
																	<div class="col-md-6">
																		<p style="font-size:120%">구매수량 : {{purchase.주문수량}}</p>
																		<p style="font-size:120%">판매구성 : {{purchase.판매구성수량}}</p>
																		<!-- <div class="mb-4">
																			<div class="row text-center ml-1" id="printMe" style="border:1px solid silver;width:300px">
																				<div class="col-12 text-center mb-0">
																					<p class="mb-0" style="font-size:90%;">Made in China&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>SKU : {{purchase.SKUID}}</span></p>
																				</div>
																				<div class="col-12 text-center mb-0">
																					<barcode :value="purchase.바코드" :tag="bctag" :options="{ lineColor: '#0275d8', text: 'Scan'}"></barcode>
																				</div>
																			</div>
																			<div class="mt-2">
																				<button class="btn btn-primary" type="button" @click="print">바코드출력</button>
																			</div>
																		</div> -->
																		<p>상품명 : {{purchase.상품명}}</p>
																		<p>SKU ID : {{purchase.SKUID}}</p>
																		<p>바코드 : {{purchase.바코드}}</p>
																		<p>구매링크 : <a :href="purchase.상품링크">{{purchase.상품링크}}</a></p>
																		<p>옵션1_중국어 : {{purchase.중국어옵션1}}</p>
																		<p>옵션2_중국어 : {{purchase.중국어옵션2}}</p>

                                                                        <p>[배송 요청사항]</p>
                                                                        <card style="color:blue;" v-if="purchase.요청매칭 && purchase.요청매칭.length > 0">
                                                                            <div class="ml-1 mt-1 mb-1 mr-1 row">
                                                                                <div class="col-5">
                                                                                    <span><b>[출고작업]</b></span><br>
                                                                                    <span>배치 : {{purchase.요청매칭[0].selectpo ? '발주배치' : '해당없음'}}</span><br>
                                                                                    <span>배치발주번호 : {{purchase.요청매칭[0].selectpo}}</span><br>
                                                                                    <span>출고유형 : {{purchase.요청매칭[0].요청내역.exportType.toUpperCase()}}</span><br>
                                                                                    <br>
                                                                                    <span><b>[검수 및 포장 작업]</b></span><br>
                                                                                    <span>기본검수 : {{purchase.요청매칭[0].요청내역.default ? 'Y' : 'N'}}</span><br>
                                                                                    <span>OPP봉투 : {{purchase.요청매칭[0].요청내역.opp ? 'Y' : 'N'}}</span><br>
                                                                                    <span>택배봉투(LDPE) : {{purchase.요청매칭[0].요청내역.LDPE ? 'Y' : 'N'}}</span><br>
                                                                                    <span>에어캡 : {{purchase.요청매칭[0].요청내역.airpacking ? 'Y' : 'N'}}</span><br>
                                                                                    <br>
                                                                                </div>
                                                                                <div class="col-7">
                                                                                    <span><b>[라벨 및 원산지 작업]</b></span><br>
                                                                                    <span>바코드 : {{purchase.요청매칭[0].요청내역.barcode ? 'Y' : 'N'}}</span><br>
                                                                                    <span>한글표시사항 : {{purchase.요청매칭[0].요청내역.한글표시사항 ? 'Y' : 'N'}}</span><br>
                                                                                    <span>원산지스티커 : {{purchase.요청매칭[0].요청내역.sticker ? 'Y' : 'N'}}</span><br>
                                                                                    <span>도장 : {{purchase.요청매칭[0].요청내역.stamp ? 'Y' : 'N'}}</span><br>
                                                                                    <span>택총 : {{purchase.요청매칭[0].요청내역.tag ? 'Y' : 'N'}}</span><br>
                                                                                    <br>
                                                                                    <span><b>[기타 구매대행 요청사항]</b></span><br>
                                                                                    <span>{{purchase.요청매칭[0].요청내역.reqcontent}}</span>
                                                                                </div>

                                                                            </div>

                                                                        </card>

                                                                        <p :style="purchase.입고오류유형 == '' ? 'font-size:120%;font-weight:bold;color:black' : 'font-size:120%;font-weight:bold;color:red'">입고오류유형 : {{purchase.입고오류유형 ? purchase.입고오류유형 : ''}}</p>
                                                                        <p style="font-size:120%;font-weight:bold;color:red" v-if="purchase.입고오류유형 == '수량불일치'">입고수량 : {{purchase.입고수량}}</p>
                                                                        <p style="font-size:120%;font-weight:bold;color:red" v-if="purchase.입고오류유형 != '' && purchase.입고오류유형 != '수량불일치'">입고오류내용 : {{purchase.입고오류내용}}</p>

                                                                        <div class="row">
																			<div class="col-md-4">
																				<button :class="importerrorbutton == '입고처리' ? 'btn btn-secondary' : 'btn btn-danger'" type="button" @click="importerror(purchase)">{{importerrorbutton}}</button>
																			</div>
																			<div class="col-md-8" v-if="showimporterror">
                                                                                <div class="custom-control custom-radio custom-control-inline">
                                                                                    <input type="radio" id="customRadio15" name="customRadio-4" class="custom-control-input" @change="changeimporterror(purchase)" v-model="purchase.처리방법" value="입고완료처리">
                                                                                    <label class="custom-control-label" for="customRadio15">입고완료처리</label>
																				</div>
																				<div class="custom-control custom-radio custom-control-inline">
																					<input type="radio" id="customRadio16" name="customRadio-4" class="custom-control-input" @change="changeimporterror(purchase)" v-model="purchase.처리방법" value="반품">
																					<label class="custom-control-label" for="customRadio16">반품</label>
																				</div>
																				<div class="custom-control custom-radio custom-control-inline">
																					<input type="radio" id="customRadio17" name="customRadio-4" class="custom-control-input" @change="changeimporterror(purchase)" v-model="purchase.처리방법" value="폐기">
																					<label class="custom-control-label" for="customRadio17">폐기</label>
																				</div>
																				<div class="custom-control custom-radio custom-control-inline">
																					<input type="radio" id="customRadio18" name="customRadio-4" class="custom-control-input" @change="changeimporterror(purchase)" v-model="purchase.처리방법" value="입고대기">
																					<label class="custom-control-label" for="customRadio18">입고대기</label>
																				</div>
																			</div>
																			<div class="col-md-12 mt-2" v-if="showimporterror && showimporterror1">
																				<div class="form-group">
																					<label for="exampleFormControlTextarea1">반품정보</label>
                                                                                    <input type="text" class="form-control" placeholder="반품수취인" v-model="purchase.반품수취인">
                                                                                    <input type="text" class="form-control" placeholder="반품연락처" v-model="purchase.반품연락처">
																					<textarea class="form-control" placeholder="반품주소" id="exampleFormControlTextarea1" rows="3" v-model="purchase.반품주소"></textarea>
                                                                                    <input type="text" class="form-control" placeholder="반품운송장번호" v-model="purchase.반품운송장번호">
																				</div>
																			</div>
																			<div class="col-md-12 mt-2" v-if="showimporterror && showimporterror2">
																				<div class="form-group">
																					<label for="exampleFormControlTextarea1">입고대기 사유</label>
																					<textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="purchase.입고대기사유"></textarea>
																				</div>
																			</div>
																		</div>



																	</div>
																</div>

															</b-modal>







                                        <a class="badge bg-success mr-2" data-toggle="tooltip" v-b-tooltip.top title="Edit"
                                            href="javascript:void(0)" @click="edittrackingno"><i class="ri-pencil-line mr-0"></i></a>
                                        <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete"
                                            href="javascript:void(0)" @click="deleteitem(purchase)"><i class="ri-delete-bin-line mr-0"></i></a>
                                    </div>
                                </td>



                            </tr>
                        </template>
                     </tableList>
                </div>
            </div>

            <div class="col-lg-12" v-if="showlist">
                <button type="button" class="btn btn-primary mr-2 mt-1" @click="download">다운로드</button>
                <button type="button" class="btn btn-success mr-2 mt-1" v-b-toggle.my-collapse>일괄변경</button>
                <button type="button" class="btn btn-secondary mr-2 mt-1" @click="deletelists">일괄삭제</button>
            </div>
            <div class="col-lg-3 mt-2 mb-4" v-if="showlist">
                <b-collapse id="my-collapse">
                    <b-card title="">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="주문번호" v-model="일괄주문번호">
                        </div>
                        <div class="form-group">
                            <input type="number" class="form-control" placeholder="주문수량" v-model="일괄주문수량">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="운송장번호" v-model="일괄운송장번호">
                        </div>
                        <button type="button" class="btn btn-success mr-2 mt-1" @click="editlists">적용</button>
                    </b-card>
                </b-collapse>
            </div>


        </div>
        <!-- Page end  -->
    </div>
</template>
<script>
import * as XLSX from 'xlsx';

import axios from 'axios';
export default {
    name:'ListPurchases',
    data(){
        return{
            title:"구매내역",
            desc1:"1688구매내역 업로드 전에 구매대행 요청을 먼저 등록해 주세요.",
            desc2:"1688구매내역을 업로드 해주세요. 구매내역 업로드 시 '구매재고'에 자동으로 추가됩니다.",
            link:{  name: 'purchase.addpurchase'},
            linktext:"1688구매내역 업로드",





            showspinner: false,
            showsearchspinner:false,
            showlist: false,
            showmatchlist:false,
            matchlists: [],
            purchaselists: [],
            purchaselistsinit: [],

            uploadstatus: 'excel file upload',
            환율:195,

            checkbox1:false,
            sku검색:'',
            상품명검색:'',
            옵션명검색:'',

            showmatch:false,
            showmatchspinner:false,
            matches:[],

            selectdate: 'today',
            selectoption:'구매일자',
            startdate: this.getTodaywith(),
            enddate: this.getTodaywith(),

            주문번호:'',
            운송장번호:'',
            판매처:'',
            발주번호:'',
            SKUID:'',
            바코드:'',

            showedittrackingno:false,

            total: 0,
            발송전 : 0,
            발송지연 : 0,
            발송완료 : 0,
            배송지연예정 : 0,
            배송지연 : 0,
            구매확정 : 0,
            입고지연 : 0,
            입고완료 : 0,
            입고수량오류 : 0,
            입고상품오류 : 0,
            배송완료 : 0,
            오류입고처리 : 0,
            오류반품처리 : 0,
            오류폐기처리 : 0,
            오류입고대기 : 0,
            반품폐기완료 : 0,


            importerrorbutton: '입고처리',
            showimporterror: false,
            showimporterror1 : false,
            showimporterror2 : false,

            currentpurchase : {},
            currentindex: 0,

            일괄주문번호: '',
            일괄주문수량: '',
            일괄운송장번호: '',

            // allcheck: false,
        }
    },

    methods: {
        // checkall(){
        //     if(this.allcheck){
        //         this.purchaselists.forEach(e => e.checked = true)
        //     } else {
        //         this.purchaselists.forEach(e => e.checked = false)
        //     }
        // },
        editlists(){
            if(!this.일괄주문번호){
                alert('변경할 주문번호를 입력해 주세요')
                return
            }
            if(confirm('총 ' + this.purchaselists.filter(e => e.주문번호.toString() == this.일괄주문번호.toString()).length + '건의 구매내역을 변경하시겠습니까?')){
                this.purchaselists.filter(e => e.주문번호.toString() == this.일괄주문번호.toString()).forEach(e => {
                    if(this.일괄주문수량){
                        e.주문수량 = parseInt(this.일괄주문수량);
                    }
                    if(this.일괄운송장번호){
                        e.운송장번호 = this.일괄운송장번호;
                    }
                })
                this.editpurchases(this.purchaselists.filter(e => e.주문번호.toString() == this.일괄주문번호.toString()))
            }
        },
        download(){
            const workBook = XLSX.utils.book_new()
            this.purchaselists.forEach(item => {
                item.주문날짜 = this.getdate(item.주문날짜)
            })
            console.log(this.purchaselists)
			const workSheet1 = XLSX.utils.json_to_sheet(this.purchaselists)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '구매내역')
			XLSX.writeFile(workBook, '구매내역.xlsx')
        },
        deleteindexfromarray(array,index){
            return array.slice(0,index).concat(array.slice(index+1))
        },
        async editpurchase(purchase){
            purchase.주문수량 = parseInt(purchase.주문수량);
            var arr = [];
            arr.push(purchase)
            await this.editpurchases(arr)
            this.edittrackingno()
        },
        editpurchases(arr){
            axios.post('/api/editpurchase',arr)
            .then( result => {
            if (result.data.요청결과 == '업데이트완료') {
                arr.forEach(e => {
                    this.$store.commit('updatepurchaseitem', e)
                    console.log(e.운송장번호)
                    console.log(this.$store.state.purchase.find(ele => ele._id == e._id).운송장번호)
                })
                alert("업데이트완료")
                return true
            }
            }).catch((e)=>{
                alert('업데이트 실패(580)')
                console.log(e);
                return true
            })
        },
        deletelists(){
            if(this.purchaselists.filter(e => e.checked).length == 0){
                alert('선택된 구매내역이 없습니다.')
                return
            }
            this.deletearr(this.purchaselists.filter(e => e.checked));
        },
        deleteitem(purchase){
            var arr = []
            arr.push(purchase)
            this.deletearr(arr);
        },
        deletearr(delarr){
            if(confirm('삭제된 내역은 복구가 불가능합니다. 그래도 삭제하시겠습니까?')){
                axios.post('/api/deletepurchase',delarr)
                .then( result => {
                if (result.data.요청결과 == '삭제완료') {
                    this.$store.commit('deletepurchase', delarr)
                    delarr.forEach(e => {
                        this.purchaselists = this.deleteindexfromarray(this.purchaselists,this.purchaselists.findIndex(ele => this.equal(ele,e)));
                    })
                    alert(result.data.deletedCount + "개 구매내역 삭제완료")
                    return true
                } else {
                    alert('삭제 실패(578)')
                    return true
                }
                }).catch((e)=>{
                    alert('삭제 실패(582)')
                    console.log(e);
                    return true
                })
            }
        },
        equal(k1,k2){
			JSON.stringify(k1) === JSON.stringify(k2)
		},
        modaldata(purchase,index){
            this.currentpurchase = purchase;
            this.currentindex = index;
            if(purchase.배송상태.includes('오류') || purchase.배송상태.includes('반품')){
                this.importerror(purchase)
                this.changeimporterror(purchase)
            }
        },
        async refreshpage(bvModalEvent){
             bvModalEvent.preventDefault()
            this.showlist = false;
            var purchase = this.purchaselists[this.currentindex]
            console.log(purchase)
            if(!this.equal(this.purchaselistsinit[this.currentindex],purchase)){
                console.log(purchase)
                if(this.purchaselistsinit[this.currentindex].처리방법){
                    console.log(this.purchaselistsinit[this.currentindex])
                    console.log(purchase)
                    if(purchase.처리방법 != this.purchaselistsinit[this.currentindex].처리방법){
                        console.log(purchase)
                        this.showlist = await this.updatepurchase(purchase)
                        this.$nextTick(() => {
                            this.$bvModal.hide('modal' + this.currentindex)
                        })
                    }
                } else {
                    console.log(purchase)
                    this.showlist = await this.updatepurchase(purchase)
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal' + this.currentindex)
                    })
                }
            } else {
                console.log(purchase)
                this.showlist = true
            }
        },
        async updatepurchase(purchase){
            var arr = [];
            arr.push(purchase);
            axios.post('/api/logisaddimport/updatepurchase',arr)
            .then( result => {
            if (result.data.요청결과 == '입고처리 완료') {
                this.$store.commit('purchaseupdate', result.data.purchase)
                return true
            } else {
                alert('업데이트 실패(579)')
                return true
            }
            }).catch((e)=>{
                alert('업데이트 실패(582)')
                console.log(e);
                return true

            })
		},
        changeimporterror(purchase){
            if(purchase.처리방법 == '입고완료처리'){
				this.showimporterror1 = false;
				this.showimporterror2 = false;
                purchase.배송상태 = '오류입고처리'
                return
            }
			if(purchase.처리방법 == '반품'){
				this.showimporterror1 = true;
				this.showimporterror2 = false;
                purchase.배송상태 = '오류반품처리'
                return
			}
            if(purchase.처리방법 == '폐기'){
				this.showimporterror1 = false;
				this.showimporterror2 = false;
                purchase.배송상태 = '오류폐기처리'
                return
            }
            if(purchase.처리방법 == '입고대기'){
				this.showimporterror1 = false;
				this.showimporterror2 = true;
				purchase.배송상태 = '오류입고대기'
                return
			}
		},
		importerror(purchase){
			if(this.importerrorbutton == '입고처리'){
                purchase.checked = true;
				this.showimporterror = true;
				this.importerrorbutton = '입고처리취소'
			} else {
                purchase.checked = false;
				this.showimporterror = false;
				this.importerrorbutton = '입고처리';
                if(purchase.입고오류유형){
                    purchase.입고오류유형 = this.purchaselistsinit.find(e => e._id == purchase._id).입고오류유형;
                    purchase.배송상태 = this.purchaselistsinit.find(e => e._id == purchase._id).배송상태;
                    purchase.입고수량 = this.purchaselistsinit.find(e => e._id == purchase._id).입고수량;
                    purchase.입고오류내용 = this.purchaselistsinit.find(e => e._id == purchase._id).입고오류내용;
                }
			}
		},

        modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
        edittrackingno(){
            this.showedittrackingno = !this.showedittrackingno;
        },
        showtotal(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit;
            this.showlist = true;
            }, 1);
        },
        showa(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '발송전')
            this.showlist = true;
            }, 1);
        },
        showb(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '발송지연')
            this.showlist = true;
            }, 1);
        },
        showc(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '발송완료')
            this.showlist = true;
            }, 1);
        },
        showd(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '배송지연예정')
            this.showlist = true;
            }, 1);
        },
        showe(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '배송지연')
            this.showlist = true;
            }, 1);
        },
        showf(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '구매확정')
            this.showlist = true;
            }, 1);
        },
        showg(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '입고지연')
            this.showlist = true;
            }, 1);
        },
        showh(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '입고완료')
            this.showlist = true;
            }, 1);
        },
        showi(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '입고수량오류')
            this.showlist = true;
            }, 1);
        },
        showj(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '입고상품오류')
            this.showlist = true;
            }, 1);
        },
        showk(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '배송완료')
            this.showlist = true;
            }, 1);
        },
        showl(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '오류입고처리')
            this.showlist = true;
            }, 1);
        },
        showm(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '오류반품처리')
            this.showlist = true;
            }, 1);
        },
        shown(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '오류폐기처리')
            this.showlist = true;
            }, 1);
        },
        showo(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '오류입고대기')
            this.showlist = true;
            }, 1);
        },
        showp(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '반품폐기완료')
            this.showlist = true;
            }, 1);
        },
        logislink(purchase){
            var url = "https://trade.1688.com/order/new_step_order_detail.htm?orderId=" + purchase.주문번호 + "&tracelog=20120313bscentertologisticsbuyer&#logisticsTabTitle"
            return url
        },
        search(){
            this.showlist = false;
            this.showsearchspinner = true;
            if(this.$store.state.purchase.length == 0){
                alert('구매리스트가 없습니다.')
                this.showsearchspinner = false;
            } else {
                this.searchpurchaselists();
                setTimeout(() => {
                    this.showsearchspinner = false;
                    this.showlist = true;
                },50)
            }
        },
        deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
        searchpurchaselists(){
            this.purchaselists = [];
            this.purchaselistsinit = [];
            var arr = this.$store.state.purchase;
            // console.log(arr)
            if(this.주문번호){
                arr = arr.filter(e => e.주문번호 == this.주문번호)
            }
            if(this.운송장번호){
                arr = arr.filter(e => e.운송장번호 == this.운송장번호)
            }
            if(this.판매처){
                arr = arr.filter(e => e.판매처 == this.판매처)
            }
            if(this.SKUID){
                arr = arr.filter(e => e.SKUID == this.SKUID)
            }
            if(this.startdate && this.enddate){
                arr = arr.filter(e => this.getdate(e.주문날짜) >= this.startdate && this.getdate(e.주문날짜) <= this.enddate)
            }
            arr.forEach(e => {
                var temp = {}
                if(this.$store.state.productdb && this.$store.state.productdb.find(el => el.바코드 == e.바코드)){
                    temp = this.deepClone(this.$store.state.productdb.find(el => el.바코드 == e.바코드))
                    e.등록이미지 = temp.등록이미지 ? temp.등록이미지 : e.등록이미지 ? e.등록이미지 : '';
                    e.상품명 = temp.상품명 ? temp.상품명 : e.상품명 ? e.상품명 : '정보없음';
                    e.바코드 = temp.바코드 ? temp.바코드 : e.바코드 ? e.바코드 : '정보없음';
                } else {
                    e.등록이미지 = e.등록이미지 ? e.등록이미지 : '';
                    e.상품명 = e.상품명 ? e.상품명 : '정보없음';
                    e.바코드 = e.바코드 ? e.바코드 : '정보없음';
                }
                e.checked = false;
                this.purchaselists.push(this.deepClone(e))
                this.purchaselistsinit.push(this.deepClone(e))
            })
            this.badgeupdate(arr)
            this.주문번호 = '';
            this.운송장번호 = '';
            this.판매처 = '';
            this.SKUID = '';
        },
        badgeupdate(arr){
            this.total = arr.length;
            this.발송전 = arr.filter(e => e.배송상태 == '발송전').length;
            this.발송지연 = arr.filter(e => e.배송상태 == '발송지연').length;
            this.발송완료 = arr.filter(e => e.배송상태 == '발송완료').length;
            this.배송지연예정 = arr.filter(e => e.배송상태 == '배송지연예정').length;
            this.배송지연 = arr.filter(e => e.배송상태 == '배송지연').length;
            this.구매확정 = arr.filter(e => e.배송상태 == '구매확정').length;
            this.입고지연 = arr.filter(e => e.배송상태 == '입고지연').length;
            this.입고완료 = arr.filter(e => e.배송상태 == '입고완료').length;
            this.입고수량오류 = arr.filter(e => e.배송상태 == '입고수량오류').length;
            this.입고상품오류 = arr.filter(e => e.배송상태 == '입고상품오류').length;
            this.배송완료 = arr.filter(e => e.배송상태 == '배송완료').length;
            this.오류입고처리 = arr.filter(e => e.배송상태 == '오류입고처리').length;
            this.오류반품처리 = arr.filter(e => e.배송상태 == '오류반품처리').length;
            this.오류폐기처리 = arr.filter(e => e.배송상태 == '오류폐기처리').length;
            this.오류입고대기 = arr.filter(e => e.배송상태 == '오류입고대기').length;
            this.반품폐기완료 = arr.filter(e => e.배송상태 == '반품폐기완료').length;
        },
        changedate(){
            var now
            var target
            if(this.selectdate == 'today'){
                this.startdate = this.getTodaywith();
                this.enddate = this.getTodaywith();
            } else {
                if(this.selectdate == 'week'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*7

                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                } else {
                    if(this.selectdate == 'month'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*30
                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                    }
                }
            }
        },
        getTodaywith(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },
        getdate(timestamp){
            var date = new Date(timestamp);
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },
        handleOk(bvModalEvent) {
			bvModalEvent.preventDefault();
			this.handleSubmit();
		},
        cancel(){
            this.$nextTick(() => {
                // this.purchaselists = [];
                // this.matchlists = [];
				// this.stockdetail = [];
				// this.stockdetailinit = {};
				// this.stockdetailinitarr = [];
				// this.showchecklist = false;
				// this.modalcheck1 = false;
                this.$bvModal.hide('modal');
			})
        },
		handleSubmit() {
            var arr = this.purchaselists.filter(e => e.매칭상태 == '매칭완료')
            if(arr.length == 0){
                alert('업로드할 구매내역이 없습니다.')
                return
            }
            axios.post('/api/addpurchase/addproducts',arr)
			.then((res) => {
				if(res.data.요청결과 == "success"){
					// console.log(res.data.purchaselist)
                    this.$store.commit('purchaseupdate',res.data.purchaselist);
                    this.$store.commit('productdbupdate',res.data.productdb);
                    setTimeout(() => {
                        this.showlist = false;
                        this.cancel();
                    },100)

				} else {
					alert("구매내역 업데이트 실패 (402)")
					console.log(res.data)
                    this.cancel();
				}
			})
			.catch((e) => {alert("구매내역 업데이트 실패 (406)");console.log(e);})

        },
        searchbutton(){
            if(this.sku검색){
                this.showmatch = false;
                this.showmatchspinner = true;
                var keyword = this.sku검색;
				this.matches = this.$store.state.productdb.filter(e => e['SKU ID'] == keyword);
                setTimeout(() => {
                    this.showmatch = true;
                    this.showmatchspinner = false;
                },10)
                return
            }
            if(this.상품명검색){
                this.showmatch = false;
                this.showmatchspinner = true;
                keyword = this.상품명검색.split(' ');
				this.matches = this.$store.state.productdb.filter(e => keyword.filter(ele => e.상품명 && e.상품명.includes(ele)).length == keyword.length);
                setTimeout(() => {
                    this.showmatch = true;
                    this.showmatchspinner = false;
                },10)
            } else {
                if(this.옵션명검색){
                    this.showmatch = false;
                    this.showmatchspinner = true;
                    keyword = this.옵션명검색.split(' ')
                    var a = this.$store.state.productdb.filter(e => keyword.filter(ele => e.옵션1_중국어 && e.옵션1_중국어.includes(ele)).length == keyword.length)
                    var b = this.$store.state.productdb.filter(e => keyword.filter(ele => e.옵션2_중국어 && e.옵션2_중국어.includes(ele)).length == keyword.length)
                    this.matches = a.concat(b);
                    setTimeout(() => {
                        this.showmatch = true;
                        this.showmatchspinner = false;
                    },10)
                } else {
                    alert('검색어를 입력해 주세요')
                }
            }
        },
        searchbuttonin(match,word){
            if(this.sku검색){
                this.showmatch = false;
                this.showmatchspinner = true;
                var keyword = this.sku검색;
				this.matches = this.$store.state.productdb.filter(e => e['SKU ID'] == keyword);
                setTimeout(() => {
                    this.showmatch = true;
                    this.showmatchspinner = false;
                },10)
                return
            }
            if(word.length > 0){
                this.showmatch = false;
                this.showmatchspinner = true;
                keyword = word.split(' ');
                console.log(keyword)
                console.log(match.상품매칭)
				this.matches = match.상품매칭.filter(e => keyword.filter(ele => (e.상품명 + e.옵션1_중국어 + e.옵션2_중국어).includes(ele)).length == keyword.length);
                setTimeout(() => {
                    this.showmatch = true;
                    this.showmatchspinner = false;
                },10)
            } else {
                this.setinit();
            }
        },
        productmatching(a,match){
            match.SKUID = a['SKU ID'];
            match.매칭상태 = '매칭완료';
            match.상품명 = a.상품명;
            match.바코드 = a.바코드;
            // match.상품링크 = a.구매링크;
            match.등록이미지 = a.등록이미지;
            this.setinit();
        },
        reqmatching(a,match){
            match.요청매칭상태 = '매칭완료';
            match.요청매칭 = [a];
            match.SKUID = a.SKUID;
            match.매칭상태 = '매칭완료';
            match.상품명 = a.상품명;
            match.바코드 = a.바코드;
            // match.상품링크 = a.구매링크;
            match.등록이미지 = a.등록이미지;
            this.setinit();
        },
        setinit(){
            this.showmatch=false;
            this.sku검색='';
            this.상품명검색='';
            this.옵션명검색='';
            this.matches = [];
        },
        matchclass(a){
            if(a == '매칭완료'){
                return 'badge badge-success';
            } else {
                return 'badge badge-warning';
            }
        },
        logisclass(purchase){
            //발송전 : light
            //발송지연 : warning
            //발송완료 : primary
            //배송지연예정 : danger
            //배송지연 : secondary
            //구매확정 : success
            //입고지연 : warning
            if(purchase.배송상태 == '배송전'){
                return 'badge badge-light';
            }
            if(purchase.배송상태 == '발송지연'){
                return 'badge badge-warning';
            }
            if(purchase.배송상태 == '발송완료'){
                return 'badge badge-primary';
            }
            if(purchase.배송상태 == '배송지연예정'){
                return 'badge badge-danger';
            }
            if(purchase.배송상태 == '배송지연'){
                return 'badge badge-secondary';
            }
            if(purchase.배송상태 == '구매확정'){
                return 'badge badge-success';
            }
            if(purchase.배송상태 == '입고지연'){
                return 'badge badge-warning';
            }
            if(purchase.배송상태 == '입고완료'){
                return 'badge badge-success';
            }
            if(purchase.배송상태 == '입고수량오류'){
                return 'badge badge-secondary';
            }
            if(purchase.배송상태 == '입고상품오류'){
                return 'badge badge-warning';
            }
            if(purchase.배송상태 == '배송완료'){
                return 'badge badge-primary';
            }
        },
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },
        datetotimestamp(serial) {
            var utc_days  = Math.floor(serial - 25569);
            var utc_value = utc_days * 86400;
            var date_info = new Date(utc_value * 1000);

            var fractional_day = serial - Math.floor(serial) + 0.0000001;

            var total_seconds = Math.floor(86400 * fractional_day);

            var seconds = total_seconds % 60;

            total_seconds -= seconds;

            var hours = Math.floor(total_seconds / (60 * 60));
            var minutes = Math.floor(total_seconds / 60) % 60;

            return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds).getTime();
        },
        async uploadfile(event) {  // ----------------------------------------------------->수정중
            this.showspinner = true;
            this.showmatchlist = false;
            var arr = [];
            var obj = {};
            var 주문번호,판매처,배송단가,할인단가,운송장번호,주문날짜,주문상태;
            const file = event.target.files[0];
            // var filename = file.name;
            let reader = new FileReader();
            // var content_pdbuploadstatus
            // this.pdbuploadstatus = "업로드 중 ...... (업로드가 완료될 때까지 기다려 주세요)";
            reader.onload = (e) => {
                // let data = reader.result;
                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary'});
                workbook.SheetNames.forEach(sheetName => {
                    const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                    console.log(roa)
                    roa.forEach(ele => {
                        if(ele['货品标题'].split(':').length == 3){
                            var op1 = ele['货品标题'].split(':')[1].split(' ').slice(0,ele['货品标题'].split(':')[1].split(' ').length-1).join(' ').trim();
                            var op2 = ele['货品标题'].split(':')[2].trim();
                        } else {
                            if(ele['货品标题'].split(':').length == 2){
                                op1 = ele['货品标题'].split(':')[1].trim();
                                op2 = '';
                            } else {
                                if(ele['货品标题'].split(':').length == 1){
                                    op1 = '';
                                    op2 = '';
                                } else {
                                    alert('관리자에 확인하세요 (244)')
                                }
                            }
                        }

                        if(ele['订单编号']){
                           주문번호 = ele['订单编号'];
                           판매처 = ele['卖家公司名'];
                           주문상태 = ele['订单状态'];
                           배송단가 = ele['运费(元)'] / (roa.filter(element => element['订单编号'] == ele['订单编号']).reduce((a,b) => a + b['数量'],0)) ;
                           할인단가 = ele['涨价或折扣(元)'] / (roa.filter(element => element['订单编号'] == ele['订单编号']).reduce((a,b) => a + b['数量'],0)) ;
                           주문날짜 = this.datetotimestamp(ele["订单付款时间"]);
                           운송장번호 = ele['运单号'];
                        } else {
                            배송단가 = 0;
                            할인단가 = 0;
                        }


                        if(op2){
                            if(this.$store.state.productdb.filter(e => e.옵션1_중국어 == op1 && e.옵션2_중국어 == op2 && e.구매링크 && e.구매링크.toString().includes(ele["Offer ID"])).length > 0){
                                // console.log(ele)
                                // console.log(this.$store.state.productdb.filter(e => e.옵션1_중국어 == op1 && e.옵션2_중국어 == op2 == op2 && e.구매링크.toString().includes(ele["Offer ID"])))
                                // console.log(this.$store.state.productdb.find(e => e.옵션1_중국어 == op1 && e.옵션2_중국어 == op2 && e.구매링크.toString().includes(ele["Offer ID"])))
                                if(this.$store.state.productdb.filter(e => e.옵션1_중국어 == op1 && e.옵션2_중국어 == op2 && e.구매링크 && e.구매링크.includes(ele["Offer ID"])).length > 1){
                                    var sku = '';
                                } else {
                                    sku = this.$store.state.productdb.find(e => e.옵션1_중국어 == op1 && e.옵션2_중국어 == op2 && e.구매링크 && e.구매링크.includes(ele["Offer ID"]))['SKU ID'];
                                }
                            } else {
                                sku = '';
                            }
                        } else {
                            if(this.$store.state.productdb.filter(e => e.옵션1_중국어 == op1 && e.구매링크 && e.구매링크.toString().includes(ele["Offer ID"])).length > 0){
                                // console.log(ele)
                                // console.log(this.$store.state.productdb.filter(e => e.옵션1_중국어 == op1 && e.구매링크.toString().includes(ele["Offer ID"])))
                                // console.log(this.$store.state.productdb.find(e => e.옵션1_중국어 == op1 && e.구매링크.toString().includes(ele["Offer ID"])))
                                if(this.$store.state.productdb.filter(e => e.옵션1_중국어 == op1 && e.구매링크 && e.구매링크.includes(ele["Offer ID"])).length > 1){
                                    sku = '';
                                } else {
                                    sku = this.$store.state.productdb.find(e => e.옵션1_중국어 == op1 && e.구매링크 && e.구매링크.includes(ele["Offer ID"]))['SKU ID'];
                                }
                            } else {
                                sku = '';
                            }
                        }


                        obj = {
                            주문날짜: 주문날짜,
                            주문상태: 주문상태,
                            상품번호: ele["Offer ID"],
                            판매처: 판매처,
                            중국어옵션1: op1,
                            중국어옵션2: op2,
                            주문수량:ele['数量'],
                            주문번호:주문번호,
                            운송장번호:운송장번호,
                            구매단가:ele['单价(元)'],
                            배송단가:배송단가,
                            할인단가:할인단가,
                            적용환율:this.환율,
                            구매sku:ele['SKU ID'],
                            SKUID:sku,
                        }
                        arr.push(obj);
                    })
                    // var lgnum = '';
                    var lgcost = 0
                    var discnt = 0
                    var ob = {}
                    arr.forEach((ele,i) => {

                        if(ele.배송단가 > 0){
                            ele.배송단가 = ele.배송단가 / (arr.filter(element => element.주문번호 == ele.주문번호).reduce((a,b) => a + b.주문수량,0)) ;
                            ele.할인단가 = ele.할인단가 / (arr.filter(element => element.주문번호 == ele.주문번호).reduce((a,b) => a + b.주문수량,0)) ;
                            lgcost = ele.배송단가
                            discnt = ele.할인단가
                        } else {
                            ele.배송단가 = lgcost;
                            ele.할인단가 = discnt;
                        }

                        if(ele.주문상태 == '交易关闭'){
                            var postatus = '주문취소'
                            var 배송기간 = 0
                        } else {
                            if(ele.주문상태 == '等待买家付款'){
                                postatus = '결제대기'
                                배송기간 = 0
                            } else {
                                if(ele.주문상태 == '等待卖家发货'){
                                    배송기간 = Math.ceil((Date.now()-ele.주문날짜)/(1000*60*60*24))
                                    if(배송기간 < 2){
                                        postatus = '발송전'
                                    } else {
                                        postatus = '발송지연'
                                    }
                                } else {
                                    if(ele.주문상태 == '等待买家确认收货'){
                                        배송기간 = Math.ceil((Date.now()-ele.주문날짜)/(1000*60*60*24))
                                        if(배송기간 < 3){
                                            postatus = '발송완료';
                                        } else {
                                            if(배송기간 < 4){
                                                postatus = '배송지연예정';
                                            } else {
                                                postatus = '배송지연';
                                            }
                                        }
                                    } else {
                                        if(ele.주문상태 == '交易成功'){
                                            배송기간 = Math.ceil((Date.now()-ele.주문날짜)/(1000*60*60*24))
                                            if(배송기간 < 6){
                                                postatus = '구매확정'
                                            } else {
                                                postatus = '입고지연'
                                            }
                                        } else {
                                            alert('[error]관리자에 문의하세요 - ' + ele.주문상태)
                                        }
                                    }
                                }
                            }
                        }

                        if(ele.SKUID > 0){
                            var 상품링크 = this.$store.state.productdb.find(element => element.구매링크 && element.구매링크.includes(ele.상품번호)).구매링크
                            var 매칭상태 = '매칭완료';
                            var 요청매칭상태 = this.reqlists.filter(f => f.SKUID == ele.SKUID && f.진행현황 == '구매전').length == 1 ? '매칭완료' : '매칭필요';
                            var 요청매칭 = this.reqlists.filter(f => f.SKUID == ele.SKUID && f.진행현황 == '구매전').length == 1 ? this.reqlists.filter(f => f.SKUID == ele.SKUID && f.진행현황 == '구매전') : [];
                            var 상품매칭 = [];
                            var 등록이미지 = this.$store.state.productdb.find(el => el['SKU ID'] == ele.SKUID).등록이미지;
                            var 상품명 = this.$store.state.productdb.find(el => el['SKU ID'] == ele.SKUID).상품명;
                            var 바코드 = this.$store.state.productdb.find(el => el['SKU ID'] == ele.SKUID).바코드;
                            var 판매구성수량 = this.$store.state.productdb.find(el => el['SKU ID'] == ele.SKUID).판매구성수량;
                        } else {
                            상품링크 = 'https://detail.1688.com/offer/' + ele.상품번호 + '.html'
                            매칭상태 = '매칭필요';
                            요청매칭상태 = '매칭필요';
                            요청매칭 = [];
                            if(this.$store.state.productdb.filter(element => element.구매링크 && element.구매링크.includes(ele.상품번호)).length > 0){
                                상품매칭 = this.$store.state.productdb.filter(element => element.구매링크 && element.구매링크.includes(ele.상품번호));
                            } else {
                                상품매칭 = ['직접선택']
                            }
                            등록이미지 = '';
                            상품명 = '';
                            바코드 = '';
                        }

                        ob = {
                            companyid:this.$store.state.user.companyid,
                            등록이미지:등록이미지,
                            상품명:상품명,
                            바코드:바코드,
                            판매구성수량:판매구성수량,
                            배송상태:postatus,
                            배송기간:배송기간,
                            주문날짜:ele.주문날짜,
                            매칭상태:매칭상태,
                            상품매칭:상품매칭,
                            요청매칭상태:요청매칭상태,
                            요청매칭:요청매칭,
                            SKUID:ele.SKUID,
                            구매sku:ele.구매sku,
                            주문수량:ele.주문수량,
                            판매처:ele.판매처,
                            상품번호:ele.상품번호,
                            상품링크:상품링크,
                            중국어옵션1:ele.중국어옵션1,
                            중국어옵션2:ele.중국어옵션2,
                            주문번호:ele.주문번호,
                            운송장번호:ele.운송장번호,
                            총구매단가: (ele.구매단가+ele.배송단가+ele.할인단가)*this.환율,
                            구매단가:ele.구매단가,
                            배송단가:ele.배송단가,
                            할인단가:ele.할인단가,
                            적용환율:this.환율,
                            site:'1688'
                        }
                        this.purchaselists.push(ob)

                        if(i == arr.length - 1){

                            console.log(arr)
                            if(this.$store.state.purchase.length > 0){
                                this.matchlists = this.purchaselists
                                .filter(element => element.매칭상태 == '매칭필요' && element.배송상태 != '결제대기')
                                .filter(element => this.$store.state.purchase.filter(el => el.주문번호 == element.주문번호 && el.상품번호 == element.상품번호 && el.중국어옵션1 == element.중국어옵션1 && el.중국어옵션2 == element.중국어옵션2).length == 0)
                            } else {
                                this.matchlists = this.purchaselists.filter(element => element.매칭상태 == '매칭필요' && element.배송상태 != '결제대기')
                            }

                            console.log(this.matchlists)
                            this.showspinner = false;
                            this.showmatchlist = true;
                        }
                    })


                    // console.log(this.$store.state.user.companyid)
                    // roa.forEach(e => {delete e._id});
                    // if (roa[0].companyid == this.$store.state.user.companyid) {
                    //     axios.post('/api/addproduct/addproductdb',roa)
                    //     .then((res) => {
                    //         console.log(res.data)
                    //         if (res.data.등록수 == '0') {
                    //             var no_reg_alarm = "이미 모든 상품이 등록되어 있습니다";
                    //             content_pdbuploadstatus = filename + " 업데이트완료 (" + res.data.등록수 + "/" + res.data.등록요청수 + ")";
                    //             this.pdbuploadstatus = content_pdbuploadstatus;
                    //             alert(no_reg_alarm);
                    //         } else {
                    //             var reg_alarm = "신규등록 상품수 : "  + res.data.등록수 + ", 전체 상품수 : " + res.data.productdb.length;
                    //             content_pdbuploadstatus = filename + " 업데이트완료 (" + res.data.등록수 + "/" + res.data.등록요청수 + ")";
                    //             this.pdbuploadstatus = content_pdbuploadstatus;
                    //             this.$store.commit('productdbupdate', res.data.productdb);
                    //             alert(reg_alarm);
                    //         }
                    //     })
                    //     .catch(console.log);
                    // } else {
                    //     alert("Company ID가 일치하지 않습니다")
                    // }
                });
            };
            reader.readAsBinaryString(file);
            // this.$refs.pdbupload.value = '';
            // this.pdbuploadstatus = content_pdbuploadstatus;
        }
    }
}
</script>
<style>
.my-class .dropdown-menu {
    max-height: 300px;
    width:800px;
    overflow-y: auto;
}
.modal-dialog {
    max-width: 90%;
    position: absolute;

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
